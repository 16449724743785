.nav-link{
    
        color: black;
        text-align: left;
        line-height: 1.5rem;
        font-weight: 550;
        font-size: 0.9rem;
        font-family: 'Sora';
}

.sidelist{
        list-style: none;
        margin-bottom: 0.5rem;
        margin-right: 0.5rem;
        border-bottom: 0.5px solid lightgray;
}
 
.sidenav .sidelist a:hover{
        color:#F19E1E;

}



.sidenav{
        margin-left: 0.8rem;
        margin-top: 0.8rem;
}

