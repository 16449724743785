html, body{
    margin:0;
    border: 0;
   width: 100%;
   background-color: rgb(250, 250, 250);
   
}

.Container{
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 0;
   
}

@media (width <= 1200px){
    .Container{
        padding-left:0;
        padding-right:0;
        margin-top: 0;
    }
}


.slide{
    height: 100% !important;;
    padding: 10px;
}





