.header_link {
    color:rgb(6, 0, 0);
    font-weight: bold;
    border: none;
    border-right: 3px solid lightgray;
    border-left: 3px solid lightgray;
    border-top: 3px solid lightgray;
    cursor: pointer;
    outline: none;
    padding: 20px;
}

.header_link:last-child{
  border-bottom: 3px solid lightgray;
}

.selected:last-child{
  border-right: 3.5px solid #F19E1E;
    border-bottom: 3.8px solid #F19E1E;
    color: #F19E1E;
}

.selected {
    border-right: 3.5px solid #F19E1E;
    border-bottom: 3.8px solid #F19E1E;
    color: #F19E1E;
}

.bullet{

list-style: disc;
color:#F19E1E;
font-size: 1.3rem;

  
}

.bullet span{
  color:#000000;
  font-size: 1rem;
  font-family:'Sora'
}

.list{
  margin-left: 1.8rem;
}


 