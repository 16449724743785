a {
    text-decoration: none;
}

li {
    list-style: none;
}


ul{
    margin:0;
    padding-left: 0;
}