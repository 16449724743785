a {
    text-decoration: none;
    color: black;
}

.logo{
    height: 40px;
    width: 200px;
    margin-top: 12px;
    box-shadow: none;
}


.MenuName{
    color: #323C40;
    background-color: transparent;
    border: none;
    padding: 10px;
    font-size: 1rem;
    font-weight: bold;
    text-transform: capitalize;
    outline: none;
    box-shadow: none;
   

}


 